import React from "react";
import Jumbotron from "./components/Jumbotron";
import Footer from "./components/Footers";
import AccountFeatures from "./components/AccountFeatures";
import Benefits from "./components/Benefits";
import Partners from "./components/Partners";
import FirstChoices from "./components/FirstChoices";
import Address from "./components/Address";
import Facilities from "./components/Facilities";
import MakeMiningAccessible from "./components/MakeMiningAccessible";
import NavBar from "./components/NavBar";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();

  const handleClickLogin = () => {
    navigate("/login");
  };

  return (
    <div className="col-center-center w-full bg-white">
      <div
        className="w-full h-[710px] bg-center"
        style={{
          backgroundImage:
            "url('https://www.genesisminingbot.com/img/gm_header_inside_farm.jpg')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <section className="col-start-center w-full h-full">
          <NavBar onClickLogin={handleClickLogin} />
          <Jumbotron />
        </section>
      </div>
      <MakeMiningAccessible />
      <AccountFeatures />
      <Facilities />
      <Benefits />
      <Partners />
      <FirstChoices />
      <Address />
      <Footer />
    </div>
  );
};

export default LandingPage;
