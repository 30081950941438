import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { useState } from "react";
import WithdrawDialog from "./WithdrawDialog";

const HomeUserPage = () => {
  const [capital, setCapital] = useState(0);
  const [deviden, setDeviden] = useState(0);
  const [speed, setSpeed] = useState(0);
  const [showWithdrawDialog, setShowWithdrawDialog] = useState(false);
  const [listHistory, setListHistory] = useState([
    {
      type: "Withdraw",
      date: "2021-10-10 10:10:10",
      amount: 1000,
      status: "Success",
    },
    {
      type: "Withdraw",
      date: "2021-10-10 10:10:10",
      amount: 1000,
      status: "Success",
    },
    {
      type: "Withdraw",
      date: "2021-10-10 10:10:10",
      amount: 1000,
      status: "Success",
    },
  ]);

  const HistoryItem = (props) => {
    const { type, date, amount, status } = props;
    return (
      <div className="row-between-center w-full bg-gray-400 px-2 py-3">
        <div className="row-start-center w-full gap-2">
          <Avatar
            className="bg-slate-700 text-white p-5"
            icon="pi pi-arrow-down-left"
          />
          <div
            className="col-center-start gap-2 w-full pt-1"
            style={{ lineHeight: "0.5" }}
          >
            <span className="font-semibold text-slate-800">{type}</span>
            <span className="text-sm text-white">{date}</span>
          </div>
        </div>
        <span className="">{amount.toLocaleString("id-ID")}</span>
      </div>
    );
  };

  const handleClickWithdraw = () => {
    setShowWithdrawDialog(true);
  };

  return (
    <div
      className="bg-contain bg-bottom w-full  bg-[#0e2e45] max-w-[40rem]"
      style={{
        height: "calc(100vh - 5rem)",
        backgroundImage:
          "url('https://www.genesisminingbot.com/img/in-equity-14-bg.svg')",
        backgroundRepeat: "no-repeat",
      }}
    >
      <WithdrawDialog
        visible={showWithdrawDialog}
        onHide={() => setShowWithdrawDialog(false)}
      />
      <section className="col-start-start px-4 py-4 w-full">
        <div className="col-start-start gap-2 w-full">
          <span className="font-semibold text-white">My Wallet</span>
          <div className="col-start-start w-full gap-3">
            {/* capital */}
            <article className="col-start-start gap-1 bg-[#E0DDAF] w-full rounded shadow-md px-4 py-2">
              <span className="text-sm font-semibold text-slate-500">
                Capital
              </span>
              <span className="text-lg font-semibold">Rp. {capital}</span>
            </article>
            {/* deviden */}
            <article className="row-between-center gap-1 bg-[#FDEBD3] w-full rounded shadow-md px-4 py-2">
              <div className="col-start-start">
                <span className="text-sm font-semibold text-slate-500">
                  Deviden
                </span>
                <span className="text-lg font-semibold">Rp. {capital}</span>
                <span className="text-sm font-semibold text-green-600">
                  {speed} / hour
                </span>
              </div>
              <Button
                className="bg-[#FCA41D] border-[#FCA41D] text-white px-2 py-1 rounded text-sm"
                onClick={handleClickWithdraw}
              >
                Withdraw
              </Button>
            </article>
          </div>
        </div>
      </section>
      <section className="col-start-start px-4 py-4 w-full">
        <div className="col-start-start gap-2 w-full">
          <span className="font-semibold text-white">Withdraw History</span>
          <div className="col-start-start w-full gap-3">
            {listHistory.map((history, index) => (
              <HistoryItem key={index} {...history} />
            ))}
          </div>
          <div className="col-center-center gap-2 w-full pt-3">
            <Button
              className="bg-[#FCA41D] border-[#FCA41D] text-white px-2 py-1 rounded text-sm"
              icon="pi pi-arrow-down"
              label="Load More History"
            />
          </div>
        </div>
      </section>
    </div>
  );
};
export default HomeUserPage;
