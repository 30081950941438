import { FaCircleCheck } from "react-icons/fa6";

const FacilityItem = (props) => {
  const { description } = props;
  return (
    <div className="row-start-center gap-4">
      <FaCircleCheck className="text-yellow-500 text-lg" />
      <span className="text-zinc-600 text-md ">{description}</span>
    </div>
  );
};

const Facilities = () => {
  return (
    <section className="row-start-start w-full px-20 py-16 gap-16 border-gray-300 border">
      <div
        className="w-7/12"
        dangerouslySetInnerHTML={{
          __html:
            '<video controls="" poster="https://res.cloudinary.com/dmynnvfip/image/upload/v1684197481/19780795_1498178170248348_1619701879998673564_o_bw8nxx.jpg"><source src="https://res.cloudinary.com/dmynnvfip/video/upload/v1684196032/Leading_Cryptocurrency_Mining_Performance___Genesis_Mining_MiningTheFuture_-_The_Series_Episode_1_u4tb6v.mp4" type="video/mp4"></video>',
        }}
      ></div>
      <div className="col-start-start max-w-[25rem] gap-6">
        <span className="text-2xl font-bold">
          The evolution of one of the world’s largest cryptocurrency mining
          facilities
        </span>
        <div className="col-start-start gap-3">
          <FacilityItem description="Energy (Iceland + Geothermal Energy)" />
          <FacilityItem description="Mining Hardware" />
          <FacilityItem description="Mining Software / Genesis Hive" />
          <FacilityItem description="Minable Cryptocurrencies (Algorithms)" />
          <FacilityItem description="Risk Management" />
          <FacilityItem description="Cloud Mining Psychology" />
        </div>
      </div>
    </section>
  );
};

export default Facilities;
