import { Image } from "primereact/image";
import { FaCircleArrowUp } from "react-icons/fa6";

const CoinBadge = (props) => {
  const { icon, name, code, speed } = props;
  return (
    <div className="row-start-center bg-[rgba(0,0,0,0.4)] px-5 py-3 rounded-md gap-3">
      <Image src={icon} alt={name} width="30" height="30" />
      <div className="col-center-start gap-1">
        <div className="row-start-center gap-2">
          <span className="text-white font-semibold text-xs">{name}</span>
          <span className="text-slate-400 text-xs">{code}</span>
        </div>
        <div className="row-start-center gap-2">
          <FaCircleArrowUp className="text-emerald-500 text-sm" />
          <span className="text-emerald-500 font-semibold text-sm">
            {speed}
          </span>
        </div>
      </div>
    </div>
  );
};

const Jumbotron = () => {
  return (
    <div className="row-center-center w-full h-full px-20 gap-10">
      <div className="col-start-start w-full gap-6">
        <span className="text-white font-bold text-6xl leading-tight">
          Start Mining Crypto Currency Today.
        </span>
        <span className="text-slate-400 text-xl font-semibold">
          It’s super simple - Your mining rigs are already set up and running.
          As soon as you’ve set up your account, you can start to mine your
          first coins using our Bitcoin cloud mining service!
        </span>
        <div className="row-center-center gap-8">
          <CoinBadge
            icon="https://www.genesisminingbot.com/img/svg/Bitcoin.svg"
            name="Bitcoin"
            code="BTC"
            speed="125 MH/s"
          />
          <CoinBadge
            icon="https://www.genesisminingbot.com/img/svg/Ethereum.svg"
            name="Ethereum"
            code="ETH"
            speed="68.00 MH/s"
          />
          <CoinBadge
            icon="https://www.genesisminingbot.com/img/svg/Dash.svg"
            name="Dash"
            code="DASH"
            speed="45.00 MH/s"
          />
        </div>
      </div>
      <Image
        src="https://www.genesisminingbot.com/img/crypto-miner-6991908-5715477.webp"
        alt="miner"
        width="700"
        height="700"
      />
    </div>
  );
};

export default Jumbotron;
