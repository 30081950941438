import { Dialog } from "primereact/dialog";
import { useState } from "react";
import {
  FaChartSimple,
  FaDatabase,
  FaGears,
  FaPenToSquare,
  FaUpload,
  FaUsers,
} from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import SideMenuComponent from "../../components/side-menu/SideMenuComponent";
import { APP_NAME } from "../../configs/constants";
import FloatingProfilePage from "./FloatingProfilePage";
import classes from "./LayoutDashboardPage.module.css";
import NavBarComponent from "./NavBarComponent";

import { AnimatePresence, motion } from "framer-motion";

const title = APP_NAME;
// const logoUrl = "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png";

const menus = [
  {
    label: "Setting User",
    to: "master/user",
    // permission: "view setting",
    // permission: "view uploaddata",
    icon: <FaUsers />,
  },
];

const LayoutDashboardPage = () => {
  const user = useSelector((state) => state.auth.user);
  const [showProfileDialog, setShowProfileDialog] = useState(false);
  const sideMenuIsVisible = useSelector((state) => state.ui.sideMenuIsVisible);

  const isSmallScreen = window.innerWidth < 640;

  const handleAvatarClick = (e) => {
    setShowProfileDialog((state) => !state);
  };

  return (
    <>
      <div className="row-start-start w-100">
        <AnimatePresence>
          {sideMenuIsVisible && (
            <SideMenuComponent
              menus={menus}
              title={title}
              // logoUrl={logoUrl}
              onChangeProfile={handleAvatarClick}
            />
          )}
        </AnimatePresence>
        {/* <NoteDialog /> */}
        <Dialog
          className="mt-6"
          showHeader={false}
          contentStyle={{ padding: "0", borderRadius: "0.5rem" }}
          position={"top-right"}
          visible={showProfileDialog}
          modal={false}
          style={{
            width: "50vw",
            marginTop: "calc(var(--height-nav-bar) + 12px )",
          }}
          draggable={false}
          resizable={false}
          onHide={() => setShowProfileDialog(false)}
        >
          <FloatingProfilePage hidden={false} />
        </Dialog>
        <motion.div
          initial={
            isSmallScreen
              ? {}
              : {
                  marginLeft: sideMenuIsVisible ? 0 : "var(--width-side-menu)",
                  width: "calc(100% - var(--width-side-menu))",
                }
          }
          animate={
            isSmallScreen
              ? {}
              : {
                  marginLeft: sideMenuIsVisible ? "var(--width-side-menu)" : 0,
                  width: sideMenuIsVisible
                    ? "calc(100% - var(--width-side-menu))"
                    : "100%",
                }
          }
          className={`col-start-start w-100`}
        >
          <NavBarComponent
            showNotif={true}
            onCLickAvatar={handleAvatarClick}
            style={{
              width: sideMenuIsVisible
                ? "calc(100% - var(--width-side-menu))"
                : "100%",
            }}
            avatarSrc={user.image}
          />
          <main
            className={`row-start-start ${classes.mainContainer} w-full pt-[var(--height-nav-bar)]`}
          >
            <Outlet />
          </main>
        </motion.div>
      </div>
    </>
  );
};

export default LayoutDashboardPage;
