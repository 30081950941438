import { Image } from "primereact/image";
import { Divider } from "primereact/divider";

const Partners = () => {
  return (
    <section className="row-center-center w-full px-20 gap-3 pt-5 pb-20">
      <span className="text-2xl font-semibold">We are in the press</span>
      <Divider layout="vertical" className="" />
      <div className="row-center-center gap-10">
        <Image
          src="https://www.genesisminingbot.com/img/in-equity-press-1.svg"
          alt="partner"
          width="140"
        />
        <Image
          src="https://www.genesisminingbot.com/img/in-equity-press-2.svg"
          alt="partner"
          width="140"
        />
        <Image
          src="https://www.genesisminingbot.com/img/in-equity-press-3.svg"
          alt="partner"
          width="140"
        />
        <Image
          src="https://www.genesisminingbot.com/img/in-equity-press-4.svg"
          alt="partner"
          width="140"
        />
        <Image
          src="https://www.genesisminingbot.com/img/in-equity-press-5.svg"
          alt="partner"
          width="140"
        />
      </div>
    </section>
  );
};
export default Partners;
