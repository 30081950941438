import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

const WithdrawDialog = (props) => {
  const { visible, onHide } = props;

  return (
    <Dialog
      header="Withdraw"
      visible={visible}
      style={{ width: "" }}
      onHide={() => onHide()}
    >
      <div className="col-start-center w-full gap-6 pt-6">
        <div className="col-start-start gap-1">
          <span className="text-sm font-semibold text-slate-400"> Amount </span>
          <InputText />
        </div>
        <Button className="w-full" label="CONFIRM" />
      </div>
    </Dialog>
  );
};
export default WithdrawDialog;
