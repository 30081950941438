// import logo from "./logo.svg";
import "./App.css";

import idLocale from "./assets/locale/id.json";

import { addLocale } from "primereact/api";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import ErrorPage from "./pages/error/ErrorPage";
import LoginPage from "./pages/login/LoginPage";

import LayoutDashboardPage from "./pages/dashboard/LayoutDashboardPage";

//import protector
import ProtectedRoute from "./pages/protected/ProtectedRoute";

//import admin page
import SignUpPage from "./pages/sign-up/SignUpPage";

//import user page
import { useSelector } from "react-redux";
import UserPage from "./pages/dashboard/master/user/UserPage";
import EditDataPage from "./pages/edit-data/EditDataPage";
import LaporanParkirPage from "./pages/laporan-parkir/LaporanParkirPage";
import UploadDataPage from "./pages/upload-data/UploadDataPage";
import ProtectedRouteUploadData from "./pages/protected/ProtectedRouteUploadData";
import LandingPage from "./pages/landing/LandingPage";
import LayoutDashboardUserPage from "./pages/dashboard-user/LayoutDashboardUserPage";
import HomeUserPage from "./pages/dashboard-user/home-user/HomeUserPage";

function App() {
  addLocale("id", idLocale.id);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />}></Route>
        <Route path="/login" element={<LoginPage />}></Route>
        {/* <Route path="/sign-up" element={<SignUpPage />}></Route> */}

        <Route
          // index
          path="/dashboard-admin"
          element={
            <ProtectedRoute>
              <LayoutDashboardPage />
            </ProtectedRoute>
          }
        >
          {/* <Route index element={getHome()}></Route> */}
          {/* <Route path="upload-data" element={<UploadDataPage />}></Route>
          <Route path="laporan-parkir" element={<LaporanParkirPage />}></Route>
          <Route path="edit-data" element={<EditDataPage />}></Route> */}

          <Route path="master">
            <Route path="user" element={<UserPage />} />
          </Route>

          {/* <Route path="setting"></Route> */}
        </Route>

        <Route
          path="/dashboard-user"
          element={
            <ProtectedRoute>
              <LayoutDashboardUserPage />
            </ProtectedRoute>
          }
        >
          <Route index element={<HomeUserPage />}></Route>
          {/* <Route path="upload-data" element={<UploadDataPage />}></Route>
          <Route path="laporan-parkir" element={<LaporanParkirPage />}></Route>
          <Route path="edit-data" element={<EditDataPage />}></Route> */}

          {/* <Route path="master">
            <Route path="master/user" element={<UserPage />} />
          </Route>

          <Route path="setting"></Route> */}
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
