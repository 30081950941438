import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NavBarUser from "./components/NavBarUser";

const LayoutDashboardUserPage = () => {
  const user = useSelector((state) => state.auth.user);

  console.log("Employee: ", user);

  const handleLogout = () => {
    console.log("Logout");
  };

  return (
    <>
      <div className="col-start-center w-full bg-[#0e2e45] min-h-screen ">
        <NavBarUser
          userName={user.name}
          userEmail={user.email}
          onClickLogout={handleLogout}
        />
        <main className={`row-center-start w-full`}>
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default LayoutDashboardUserPage;
