import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { FaRightFromBracket } from "react-icons/fa6";

const NavBarUser = (props) => {
  const { userName, userEmail, onClickLogout } = props;

  const firstLetterOfUserName = userName
    ? userName.charAt(0).toUpperCase()
    : "-";

  const handleClickLogout = (e) => {
    e.preventDefault();
    onClickLogout();
  };

  return (
    <nav className="row-between-center w-full border-b-2 px-2 py-4 bg-[#072642] h-[5rem]">
      <div className="row-start-start gap-2">
        <Avatar
          className="bg-slate-500 text-white"
          shape="circle"
          size="large"
          label={firstLetterOfUserName}
        />
        <div className="col-center-start">
          <span className="font-semibold text-white">{userName ?? "-"}</span>
          <span className="text-sm text-slate-400">{userEmail ?? "-"}</span>
        </div>
      </div>
      <Button
        severity="danger"
        className="text-lg gap-3"
        onClick={handleClickLogout}
      >
        <span>Log Out </span>
        <FaRightFromBracket />
      </Button>
    </nav>
  );
};

export default NavBarUser;
