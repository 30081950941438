import React from "react";
import { FaLandmark, FaUsers } from "react-icons/fa6";

const BenefitItem = (props) => {
  const { icon, title, description, image } = props;

  const newIcon = React.cloneElement(icon, { className: "text-3xl" });

  return (
    <div
      className="row-start-start gap-4 bg-right bg-fit bg-[#FCA41D] rounded-lg px-8 py-8 w-full"
      style={{
        backgroundImage: `url('${image}')`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="col-start-start gap-10 w-full">
        <div className="row-start-center gap-4">
          {newIcon}
          <span className="text-xl font-semibold">{title}</span>
        </div>
        <span className="text-md text-white font-normal max-w-[25rem]">
          {description}
        </span>
      </div>
    </div>
  );
};

const Benefits = () => {
  return (
    <section className="row-start-start w-full px-20 py-16 gap-10">
      <BenefitItem
        icon={<FaLandmark />}
        title="Fully regulated"
        description="Genesis Mining Bot is a fully regulated cloud mining platform as any other financial service provider."
        image="https://www.genesisminingbot.com/img/in-equity-9-bg-1.png"
      />
      <BenefitItem
        icon={<FaUsers />}
        title="Expert services"
        description="We provide some of the world's best cloud mining services that enable our users earn everyday."
        image="https://www.genesisminingbot.com/img/in-equity-9-bg-2.png"
      />
    </section>
  );
};

export default Benefits;
