// DEV
const HOST_URL =
  "https://api.enigmagenesis.cloud";
const HOST_URL_FILE_MANAGER = "https://files-manager.miseven.net";
const MY_URL = "https://enigmagenesis.cloud";
const SLC_LOGIN_URL = "http://localhost:3200/login";
const APP_NAME = "ENIGMAGENESIS";
const APP_LOGIC_NAME = "enigmagenesis";
const SECRET_KEY = "SECRET#1101";
const TAG = "enigmagenesis-";

// PRODUCTION
// const HOST_URL = "http://192.168.1.134/produksi/backend/public";
// const HOST_URL_FILE_MANAGER = "http://192.168.1.134/mine/images-gallery/public";
// const MY_URL = "http://produksi.localhost";
// const SLC_LOGIN_URL = "http://localhost:3200/login";
// const APP_NAME = "Produksi";
// const APP_LOGIC_NAME = "sttc-produksi";
// const SECRET_KEY = "SECRET#1101";

export {
  HOST_URL,
  MY_URL,
  HOST_URL_FILE_MANAGER,
  APP_NAME,
  APP_LOGIC_NAME,
  SLC_LOGIN_URL,
  SECRET_KEY,
  TAG,
};
