import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { useDispatch, useSelector } from "react-redux";
import TableDataComponent from "../../components/parking-excel/TableDataComponent";
import TotalLineComponent from "../../components/parking-excel/TotalLineComponent";
import { setLayoutDashboardTitle } from "../../store/ui-slice";
import { useNavigate } from "react-router-dom";
import useAPIRequest from "../../custom_hooks/simple/useAPIRequest";
import { format } from "date-fns";
import onErrorToast from "../../utils/OnErrorToast";
import onSuccessToast from "../../utils/OnSuccessToast";

const title = "Edit Data";

const defaultSingleData = {
  id: -1,
  no: 0,
  jenis: "",
  masuk_tanggal: format(Date.now(), "yyyy-MM-dd HH:mm:ss"),
  masuk_op: "",
  keluar_tanggal: format(Date.now(), "yyyy-MM-dd HH:mm:ss"),
  keluar_kasir: "",
  nomor_plat: "",
  lama: "",
  pembayaran: "",
  overnight: "",
  tiket_hilang: "",
  nomor_manual: "",
  nomor_voucher: "",
  jumlah: 0,
  jumlah_edited: 0,
};

const EditDataPage = (props) => {
  const navigate = useNavigate();
  const permissions = useSelector((state) => state.auth.permissions);

  const toastRef = useRef(null);
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const [datas, setDatas] = useState([]);
  const { requestGet, requestDelete, requestPost, loading } = useAPIRequest();

  useEffect(() => {
    if (!permissions.includes("view editdata")) {
      navigate("/");
    }
    dispatch(setLayoutDashboardTitle({ title }));

    reloadData();
  }, []);

  const generateFilter = () => {
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    return `masuk_tanggal:>=:${format(
      date,
      "yyyy-MM-dd"
    )} 00:00:00;masuk_tanggal:<:${format(nextDate, "yyyy-MM-dd")} 00:00:00;`;
  };

  const saveData = async ({ data, index }) => {
    if (data.id <= 0) {
      delete data.id;
    }

    const postResult = await requestPost({
      fullUrl: "api/transaksi/dataparkir/save",
      isForm: false,
      body: data,
      onSuccess: ({ message, data: d }) => {
        if (d) {
          const copyData = [...datas];
          copyData[index] = d;
          setDatas(copyData);

          onSuccessToast({
            toast: toastRef,
            message: "Data berhasil disimpan",
            data: {},
          });
        }
      },
      onError: ({ message, data }) => {
        onErrorToast({
          toast: toastRef,
          message: "Gagal menyimpan data",
          data: {},
        });
      },
    });
  };

  const deleteData = async (data) => {
    const deleteResult = await requestDelete({
      fullUrl: "api/transaksi/dataparkir/delete",
      isForm: false,
      ids: [data.id],
      onSuccess: ({ message, data: d }) => {
        if (d) {
          const newData = datas.filter((el) => el.id !== data.id);
          setDatas(newData);

          onSuccessToast({
            toast: toastRef,
            message: "Data berhasil dihapus",
            data: {},
          });
        }
      },
      onError: ({ message, data }) => {
        onErrorToast({
          toast: toastRef,
          message: "Gagal menghapus data",
          data: {},
        });
      },
    });
  };

  const reloadData = () => {
    setDatas([]);
    requestGet({
      fullUrl: "api/transaksi/dataparkir/data",
      params: {
        filter: generateFilter(),
        page: 1,
        take: 1000,
        order: "id",
        order_method: "ASC",
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          for (let i = 0; i < data.length; i++) {
            data[i].no = i + 1;
          }
          setDatas(data);
        }
      },
      onError: ({ message, data }) => {},
    });
  };

  const handleTambahData = (event) => {
    event.preventDefault();
    const newData = [...datas, { ...defaultSingleData }];
    const randomNegativeNumber = Math.floor(Math.random() * -1000);
    newData[newData.length - 1].id = randomNegativeNumber;
    setDatas(newData);
  };

  const reloadHandler = (event) => {
    reloadData();
  };

  return (
    <div className={`col-start-start p-4 gap-4 w-100`}>
      <Toast ref={toastRef} />
      <ConfirmDialog />
      <div className="elevated-card col-start-start w-full gap-4">
        {/* header - tanggal & upload */}
        <div className="row-between-center gap-3 w-full">
          <div className="row-start-center gap-2">
            <span>Tanggal</span>
            <Calendar
              className="max-w-[12rem]"
              value={date}
              onChange={(e) => setDate(e.value)}
              showIcon
            />
            <Button
              label="Load Data"
              icon="pi pi-refresh"
              loading={loading}
              onClick={reloadHandler}
            />
          </div>
          <div className="row-end-center gap-2">
            <Button
              label="Tambah Data"
              icon="pi pi-plus"
              onClick={handleTambahData}
            />
          </div>
        </div>

        {/* tabel */}
        <TableDataComponent
          data={datas}
          setData={setDatas}
          onSave={saveData}
          onDelete={deleteData}
          editable
        />

        {/* total */}
        <TotalLineComponent
          listData={datas}
          labelField={"jenis"}
          totalField={"jumlah"}
        />
      </div>
    </div>
  );
};

export default EditDataPage;
