import { motion } from "framer-motion";
import { Button } from "primereact/button";
import { FaArrowLeft, FaIdCard, FaRightFromBracket } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth-slice";
import { useNavigate } from "react-router-dom";
import { toggleSideMenuVisibility } from "../../store/ui-slice";
import MenuGroupItemComponent from "./MenuGroupItemComponent";
import MenuHeaderComponent from "./MenuHeaderComponent";
import MenuItemComponent from "./MenuItemComponent";
import MenuTitleComponent from "./MenuTitleComponent";
import classes from "./SideMenuComponent.module.css";

const SideMenuComponent = (props) => {
  const permissions = useSelector((state) => state.auth.permissions);
  const { onChangeProfile } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const listMenu = () => {
    const componentMenu = [];
    for (let i = 0; i < props.menus.length; i++) {
      let type = props.menus[i].type;
      let label = props.menus[i].label;
      let to = props.menus[i].to;
      let icon = props.menus[i].icon;
      let children = props.menus[i].children;
      let permission = props.menus[i].permission;

      if (permission) {
        if (!permissions.includes(permission)) {
          continue;
        }
      }

      if (type) {
        if (type === "menu-title") {
          componentMenu.push(<MenuHeaderComponent key={i} label={label} />);
        } else if (type === "menu-group") {
          const secondChilds = [];
          for (let j = 0; j < children.length; j++) {
            let childLabel = children[j].label;
            let childTo = children[j].to;
            let childIcon = children[j].icon;
            secondChilds.push(
              <MenuItemComponent
                key={`${i} ${j}`}
                className={`${classes.menuItem}`}
                to={childTo}
                label={childLabel}
                icon={childIcon}
                index={j}
              />
            );
          }

          componentMenu.push(
            <MenuGroupItemComponent
              key={i}
              className={`${classes.menuHeader}`}
              label={label}
              icon={icon}
            >
              {secondChilds}
            </MenuGroupItemComponent>
          );
        }
      } else {
        componentMenu.push(
          <MenuItemComponent
            key={i}
            className={`${classes.menuItem}`}
            to={to}
            label={label}
            icon={icon}
          />
        );
      }
    }
    componentMenu.push(
      <MenuHeaderComponent key={props.menus.length + 1} label="Lain-Lain" />
    );

    return componentMenu;
  };

  const handleArrowBack = () => {
    dispatch(toggleSideMenuVisibility());
  };

  const handleChangeProfile = (e) => {
    e.preventDefault();

    if (onChangeProfile) {
      onChangeProfile();
    }
  };

  const handleLogout = (e) => {
    e.preventDefault();

    dispatch(logout());
    navigate("/login");
  };

  return (
    <motion.section
      // layout
      initial={{ x: -100 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -100, opacity: 0, width: 0 }}
      className={`${classes.wrapper} ${props.className ?? ""} pb-5`}
    >
      <div className="row-between-start">
        <MenuTitleComponent
          className="mb-2"
          label={props.title}
          logoUrl={props.logoUrl}
        />
        <div
          className={`${classes.hamburgerButton} row-center-center text-[var(--color-text)] bg-[var(--color-primary-light)] min-w-[40px] min-h-[var(--height-nav-bar)]`}
          onClick={handleArrowBack}
        >
          <FaArrowLeft />
        </div>
      </div>
      {listMenu()}
      <div className="px-2">
        <Button
          className="w-full text-zinc-500"
          text
          onClick={handleChangeProfile}
        >
          <div className="row-start-center gap-3">
            <FaIdCard />
            Change Profile
          </div>
        </Button>
      </div>
      <div className="px-2">
        <Button className="w-full text-zinc-500" text onClick={handleLogout}>
          <div className="row-start-center gap-3">
            <FaRightFromBracket />
            Logout
          </div>
        </Button>
      </div>
      {/* <MenuItemComponent
        key={props.menus.length + 2}
        className={`${classes.menuItem}`}
        to={""}
        label={"Logout"}
        icon={<FaLock />}
        onClick={() => {
          console.log("ANJING");
        }}
      /> */}
    </motion.section>
  );
};

export default SideMenuComponent;
