import { useState } from "react";
import { FaBars } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonAvatar from "../../components/components/buttons/ButtonAvatar";
import useAPIRequest from "../../custom_hooks/simple/useAPIRequest";
import { toggleSideMenuVisibility } from "../../store/ui-slice";
import classes from "./NavBarComponent.module.css";

const NavBarComponent = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { requestGet, requestGetOne, requestPost, requestDelete, loading } =
    useAPIRequest();

  const permissions = useSelector((state) => state.auth.permissions);
  const user = useSelector((state) => state.auth.user);
  const depot = useSelector((state) => state.app.depot);
  const wilayah = useSelector((state) => state.app.wilayah);
  const listDepot = useSelector((state) => state.app.listDepot);
  const listWilayah = useSelector((state) => state.app.listWilayah);

  const taskListContent = useSelector((state) => state.ui.taskListContents);
  const noteCount = useSelector((state) => state.ui.noteCount);

  // const [wilayahs, setWilayahs] = useState([]);
  // const [wilayahs, setWilayahs] = useState([
  //   useSelector((state) => state.app.wilayah),
  // ]);
  // const [selectedWilayah, setSelectedWilayah] = useState({});
  const [disableSelectWilayah, setDisableSelectWilayah] = useState(false);

  const [taskListCount, setTaskListCount] = useState(0);

  // const [depots, setDepots] = useState([]);
  // const [selectedDepot, setSelectedDepot] = useState({});
  const [disableSelectDepot, setDisableSelectDepot] = useState(false);

  const pathSegments = location.pathname.split("/");
  const lastPathSegment = pathSegments[pathSegments.length - 1];

  const bcHome = { icon: "pi pi-home", url: "/" };

  const handleHamburger = (e) => {
    dispatch(toggleSideMenuVisibility());
  };

  const title = useSelector((state) => state.ui.layoutDashboardTitle);

  const handleDefaultAvatarClick = (e) => {
    console.log("Handle Avatar Click");
  };
  const handleAvatarClick = props.onCLickAvatar || handleDefaultAvatarClick;

  return (
    <nav
      className={`${classes.wrapper} row-between-center ps-3 pe-3 w-100 ${
        props.className ?? ""
      } fixed`}
      style={props.style ?? {}}
    >
      <div className={`row-start-center gap-3`}>
        <div
          className={`${classes.hamburgerButton} row-center-center`}
          onClick={handleHamburger}
        >
          <FaBars />
        </div>
        <div className={`${classes.title} row-start-center`}>{title}</div>
      </div>
      <div className="row-end-center gap-5 ">
        <div className="row-center-center gap-3"></div>
        <ButtonAvatar
          className="row-center-center"
          onClick={handleAvatarClick}
          label={user.name.charAt(0)}
          // src={props.avatarSrc}
        />
      </div>
    </nav>
  );
};

export default NavBarComponent;
