import classes from "./ButtonAvatar.module.css";
import { Avatar } from "primereact/avatar";

const ButtonAvatar = (props) => {
  const { label, src, onClick } = props;
  return (
    <Avatar
      className={`${classes.wrapper} ${props.className ?? ""}`}
      shape="circle"
      onClick={onClick}
      image={src}
      label={label}
    />
  );
};

export default ButtonAvatar;
