import { FaLocationDot } from "react-icons/fa6";

const Address = () => {
  return (
    <section className="col-start-start bg-[#0e2e45] w-full px-20 py-10">
      <div className="col-center-center bg-[#475467] border-4 border-[#344054] w-[3rem] h-[3rem] rounded-full">
        <FaLocationDot className="text-white text-xl" />
      </div>
      <span className="text-zinc-200 font-bold text-lg pt-4">Office</span>
      <span className="text-zinc-200">
        Genesis Mining Ltd. Chinachem Century Tower, 31/F 178 Gloucester Road
        Wanchai, Hong Kong
      </span>
    </section>
  );
};

export default Address;
