import { Image } from "primereact/image";

const AccountFeature = (props) => {
  const { number, title, description } = props;

  return (
    <div className="row-start-start gap-4 border-gray-500 border rounded-md px-4 py-4 w-[30rem]">
      <div className="col-center-center min-w-[3.5rem] min-h-[3.5rem] bg-black rounded-full">
        <span className="text-2xl font-semibold text-center text-white ">
          {number}
        </span>
      </div>
      <div className="col-start-start gap-1">
        <span className="text-lg text-white font-semibold">{title}</span>
        <span className="text-gray-400 text-md">{description}</span>
      </div>
    </div>
  );
};

const AccountFeatures = () => {
  return (
    <section className="col-start-start w-full px-20 py-1 gap-4 pb-5">
      <div
        className="col-start-start bg-cover bg-center w-full h-full rounded-md bg-[#0e2e45] px-12 py-12 gap-10"
        style={{
          backgroundImage:
            "url('https://www.genesisminingbot.com/img/in-equity-12-bg.png')",
          backgroundRepeat: "no-repeat",
        }}
      >
        <span className="text-4xl font-semibold text-white">
          Try Genesis Mining Bot today!
        </span>
        <div className="row-start-end w-full">
          <div className="col-start-start gap-5 w-full">
            <AccountFeature
              number="1"
              title="Your hardware is already running"
              description="Don’t wrestle with rig assembly and hot, noisy miners at home. We have the fastest bitcoin mining hardware running for you already!"
            />
            <AccountFeature
              number="2"
              title="Mine alternative cryptocurrencies"
              description="You can mine any cryptocurrency available in our catalogue! Switch your mining power on the fly for all the coins using our bitcoin mining website."
            />
            <AccountFeature
              number="3"
              title="Get your first mining output today"
              description="You will get periodic mining outputs to your designated wallet. Try our Bitcoin mining platform now!"
            />
          </div>
          <div className="col-end-end w-full h-full">
            <Image
              width="1000"
              src="https://www.genesisminingbot.com/img/in-equity-12-mockup.png"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountFeatures;
