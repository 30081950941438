import { Image } from "primereact/image";
import React from "react";
import { FaCircleCheck } from "react-icons/fa6";

const FirstChoices = () => {
  return (
    <section className="row-between-center w-full px-20 gap-10 bg-[#FCA41D]">
      <div
        className="row-between-center bg-fill bg-top w-full h-full py-10"
        style={{
          backgroundImage:
            "url('https://www.genesisminingbot.com/img/in-equity-14-bg.svg')",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="row-start-center">
          <Image
            className="z-20"
            src="https://www.genesisminingbot.com/img/in-equity-5-award-3.svg"
            width="130"
          />
          <div className="col-start-start border-white border rounded-xl bg-white/10 backdrop-blur-sm pl-20 pr-6 py-3 z-10 translate-x-[-4rem]">
            <span className="text-3xl font-bold">Miners #1 Choice</span>
            <span className="text-2xl">BEST HASHPOWER PROVIDER</span>
            <span className="text-sm text-blue-600 pt-2">
              Voted by industry professionals
            </span>
          </div>
        </div>
        <div className="col-center-start gap-2 pr-20">
          <div className="row-start-center gap-3">
            <FaCircleCheck className="text-blue-600 text-2xl" />
            <span className="font-semibold text-lg">Zero Commissions</span>
          </div>
          <div className="row-start-center gap-3">
            <FaCircleCheck className="text-blue-600 text-2xl" />
            <span className="font-semibold text-lg">Zero Deposit Fees</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FirstChoices;
