import { Image } from "primereact/image";

const FeatureBadge = (props) => {
  const { icon, title, description } = props;
  return (
    <div className="row-start-center bg-white px-6 py-5 w-full gap-5 rounded-md shadow-lg">
      <Image src={icon} alt={title} width="80" height="80" />
      <div className="col-start-start gap-2">
        <span className="font-semibold text-lg">{title}</span>
        <span className="text-gray-500 text-sm">{description}</span>
      </div>
    </div>
  );
};

const MakeMiningAccessible = () => {
  return (
    <section
      className="col-start-start w-full bg-auto bg-right-top px-20 py-16 gap-4"
      style={{
        backgroundImage:
          "url('https://www.genesisminingbot.com/img/in-equity-11-bg.png')",
        backgroundRepeat: "no-repeat",
      }}
    >
      <span className="text-5xl font-semibold">
        We are making mining accessible to everyone
      </span>
      <span className=" text-xl text-gray-500">
        We are uniting all key aspects of running an efficient cryptocurrency
        mining operation. From building highly efficient data centers to
        providing a streamlined mining system for our users.
      </span>

      <div className="col-center-center gap-8 w-full pt-3">
        <div className="row-center-center gap-8">
          <FeatureBadge
            icon="https://www.genesisminingbot.com/img/in-equity-11-icon-1.svg"
            title="Daily Mining Outputs"
            description="Your mining outputs will be added to your account daily and automatically"
          />
          <FeatureBadge
            icon="https://www.genesisminingbot.com/img/in-equity-11-icon-2.svg"
            title="Advanced Mining Technology"
            description="We’re providing some of the highest performing mining systems that exist"
          />
          <FeatureBadge
            icon="https://www.genesisminingbot.com/img/in-equity-11-icon-3.svg"
            title="Diverse Mining Portfolio"
            description="Over 10+ mineable major cryptocurrencies mining algorithms"
          />
        </div>
        <div className="row-center-center gap-8">
          <FeatureBadge
            icon="https://www.genesisminingbot.com/img/in-equity-11-icon-4.svg"
            title="Freedom of Allocation"
            description="Allocate your hash power to other cryptocurrencies within your account"
          />
          <FeatureBadge
            icon="https://www.genesisminingbot.com/img/in-equity-11-icon-5.svg"
            title="Intuitive Dashboard"
            description="All the crypto mining data and charts that you need, when you need them"
          />
          <FeatureBadge
            icon="https://www.genesisminingbot.com/img/in-equity-11-icon-6.svg"
            title="Secure & Private"
            description="Our platform promotes privacy and keeps collected user data safe"
          />
        </div>
      </div>
    </section>
  );
};
export default MakeMiningAccessible;
